import { AnimatePresence } from 'framer-motion';
import { lazy, Suspense } from 'react';
import { HashRouter as Router, Route, Routes} from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop';
// import AppFooter from './components/shared/AppFooter';
import AppHeader from './components/shared/AppHeader';
import './css/App.css';
import UseScrollToTop from './hooks/useScrollToTop';
// import useThemeSwitcher from './hooks/useThemeSwitcher';
import { useEffect, useState } from 'react';

const About = lazy(() => import('./pages/AboutMe'));
const Contact = lazy(() => import('./pages/Contact.jsx'));
const Home = lazy(() => import('./pages/Home'));
const Projects = lazy(() => import('./pages/Projects'));
const ProjectSingle = lazy(() => import('./pages/ProjectSingle.jsx'));

function App() {

	if(!("theme" in localStorage))
		localStorage.setItem('theme', 'light');

	const [activeTheme, setTheme] = useState(localStorage.theme);

	useEffect(() => {
		const root = window.document.documentElement;

		root.classList.remove(activeTheme);
		root.classList.add(activeTheme);
		localStorage.setItem('theme', activeTheme);
	}, [activeTheme]);

	const switchTheme = () => {
		setTheme(activeTheme === 'dark' ? 'light' : 'dark')
		document.documentElement.classList.toggle('dark');
	}

	// const [activeTheme, setTheme] = useThemeSwitcher();

	return (
		<AnimatePresence>
			<div className=" bg-secondary-light dark:bg-primary-dark transition duration-300">
				<Router>
					{/* <ScrollToTop /> */}
					<AppHeader activeTheme={activeTheme} switchTheme={switchTheme}/>
					<Suspense fallback={""}>
						<Routes>
							<Route path="/" element={<Home activeTheme={activeTheme} />} />
							<Route path="projects" element={<Projects />} />
							{/* <Route
								path="projects/single-project"
								element={<ProjectSingle />}
							/> */}

							<Route
								path="projects/:id"
								element={<ProjectSingle />}
							/>

							<Route path="about" element={<About />} />
							<Route path="contact" element={<Contact />} />
						</Routes>
					</Suspense>
					{/* <AppFooter /> */}
				</Router>
				{/* <UseScrollToTop /> */}
			</div>
		</AnimatePresence>
	);
}

export default App;
